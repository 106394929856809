import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Modal from 'blocks/Modal';

import CasinoModal from '../casino/CasinoModal';
import DeleteCasino from '../casino/DeleteCasino';

import CasinoDetails from '../casino-details';
import AppContext from 'services/context';
import CasinoService from 'services/casino.service';
import History from 'services/history';

const GroupRow = ({ group, editGroup, deleteGroup, onGroupSelect }) => {
  return (
    <tr>
      <td>
        <Link to={'#'} onClick={() => onGroupSelect(group)}>
          {group.name}
        </Link>
      </td>
      <td>
        <button
          className="button is-link"
          type="button"
          onClick={() => {
            editGroup(group);
          }}
        >
          Edit
        </button>
        <button
          className="button is-danger"
          type="button"
          onClick={() => {
            deleteGroup(group);
          }}
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

const GroupRowsEmpty = () => {
  return (
    <tr>
      <td colSpan={2}>There are not groups. You can add one below.</td>
    </tr>
  );
};

const CasinoGroups = ({ match }) => {
  const { uid } = match.params;

  const { state, dispatch } = useContext(AppContext);
  const { user } = state;

  const [casino, setCasino] = useState({});
  const [groupModal, setGroupModal] = useState(false);
  const [editGroup, setEditGroup] = useState(false);
  const [deleteGroup, setDeleteGroup] = useState(false);
  const [group, setGroup] = useState(undefined);

  useEffect(() => {
    const userHasAccess =
      user && (user.casinos.includes(uid) || user.permissions.admin);

    if (userHasAccess) {
      CasinoService.getCasino(uid).then(casino => {
        // dispatch({
        //   type: 'UPDATE_CASINO_IN_LIST',
        //   payload: casino
        // });

        setCasino(casino);
      });
    } else {
      History.push('/');
    }
  }, [user, state, uid, dispatch]);

  const selectGroup = group => {
    setGroup(group);
  };

  const casinoGroups = casino.groups ? casino.groups : [];

  const groupList = casinoGroups.map(group => (
    <GroupRow
      key={group.uid}
      group={group}
      editGroup={setEditGroup}
      deleteGroup={setDeleteGroup}
      onGroupSelect={selectGroup}
    />
  ));

  if (group) {
    return <CasinoDetails casino={group} onGroupSelect={selectGroup} />;
  } else {
    return (
      <>
        <section>
          <article>
            <p>
              <Link to={'/dashboard'}>&lt; Go back to dashboard</Link>
            </p>
            <h1 className="title is-1">{casino.name}</h1>
          </article>
          <table className="table is-bordered is-hoverable is-fullwidth">
            <thead>
              <tr>
                <td>Name</td>
                <td>Options</td>
              </tr>
            </thead>
            <tbody>
              {groupList.length > 0 ? groupList : <GroupRowsEmpty />}
            </tbody>
          </table>

          <button
            className="button is-success"
            type="button"
            onClick={() => setGroupModal(true)}
          >
            Add table
          </button>

          {groupModal && (
            <Modal closeModal={() => setGroupModal(false)}>
              <CasinoModal
                type="group"
                casinoId={casino.uid}
                onCasinoFinish={() => setGroupModal(false)}
              />
            </Modal>
          )}

          {editGroup && (
            <Modal closeModal={() => setEditGroup(false)}>
              <CasinoModal
                type="group"
                casinoId={casino.uid}
                casino={editGroup}
                onCasinoFinish={() => setEditGroup(false)}
              />
            </Modal>
          )}

          {deleteGroup && (
            <Modal closeModal={() => setDeleteGroup(false)}>
              <DeleteCasino
                type="group"
                casinoId={casino.uid}
                casino={deleteGroup}
                onCasinoFinish={() => setDeleteGroup(false)}
              />
            </Modal>
          )}
        </section>
      </>
    );
  }
};

export default CasinoGroups;

import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import AppContext from 'services/context';
import AuthService from 'services/auth.service';
import History from 'services/history';

import './index.css';

const NavBar = () => {
  const { state, dispatch } = useContext(AppContext);
  const { user } = state;

  const [active, setActive] = useState(false);

  const isActiveClass = active ? 'is-active' : '';
  const hamburgerClass = ['navbar-burger', 'burger', isActiveClass].join(' ');
  const menuClass = ['navbar-menu', isActiveClass].join(' ');

  const toggleActive = () => setActive(!active);

  const goToLoginPage = () => {
    History.push('/login');
  };

  const logout = () => {
    AuthService.logout().then(() => {
      dispatch({
        type: 'RESTART_STATE'
      });

      goToLoginPage();
    });
  };

  return (
    <>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <Link className="navbar-item" to="/dashboard">
            <img
              src="/images/softsygn-text.png"
              alt="Softsygn logo"
              width="112"
              height="28"
            />
          </Link>

          <button
            className={hamburgerClass}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbar"
            onClick={toggleActive}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </button>
        </div>

        <div id="navbar" className={menuClass}>
          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                {user ? (
                  <>
                    <Link to="/change-password" className="button is-link">
                      Change password
                    </Link>
                    <button className="button is-warning" onClick={logout}>
                      Log out
                    </button>
                  </>
                ) : (
                  <button className="button is-link" onClick={goToLoginPage}>
                    Log in
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;

import React, { useState, useContext, useEffect } from 'react';

import AppContext from 'services/context';
import UserService from 'services/user.service';

import './LinkCasinoToUser.js';

const CasinoBlock = ({ casino, checked, onChange }) => {
  const checkboxChange = ({ target }) => {
    const { checked } = target;
    if (checked) {
      onChange({
        type: 'ADD_CASINO',
        payload: casino.uid
      });
    } else {
      onChange({
        type: 'REMOVE_CASINO',
        payload: casino.uid
      });
    }
  };

  return (
    <div className="panel-block">
      <label className="checkbox">
        <input type="checkbox" checked={checked} onChange={checkboxChange} />
        {casino.name}
      </label>
    </div>
  );
};

const LinkCasinoToUser = ({ user, onUserFinish }) => {
  const { state, dispatch } = useContext(AppContext);
  const { casinosList } = state;

  const [casinos, setCasinos] = useState([]);
  const [notification, setNotification] = useState('');
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (user) {
      setCasinos(user.casinos);
    }
  }, [user]);

  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);

    const userObject = {
      casinos
    };

    UserService.updateUser(user.uid, userObject)
      .then(user => {
        dispatch({
          type: 'UPDATE_USER_IN_LIST',
          payload: user
        });

        onUserFinish();
      })
      .catch(err => {
        setDisabled(false);

        setNotification(err.message);
      });
  };

  const updateCasinos = action => {
    switch (action.type) {
      case 'ADD_CASINO':
        setCasinos([...casinos, action.payload]);
        break;
      case 'REMOVE_CASINO':
        setCasinos(casinos.filter(uid => uid !== action.payload));
        break;
      default:
        return;
    }
  };

  const casinoBlocks = casinosList.map(casino => {
    const userHasCasino = casinos.includes(casino.uid);
    return (
      <CasinoBlock
        key={casino.uid}
        casino={casino}
        checked={userHasCasino}
        onChange={updateCasinos}
      />
    );
  });

  return (
    <>
      {notification && (
        <article className="panel">
          <p className="notification is-warning app-add-user__form-panel--notification">
            {notification}
          </p>
        </article>
      )}

      <article className="panel">
        <div className="panel-heading">Link casinos to user</div>
        {casinoBlocks}
        <form onSubmit={handleSubmit}>
          <div className="panel-block">
            <button
              className="button is-fullwidth is-link"
              type="submit"
              disabled={disabled}
            >
              Done
              {disabled && <img src="/images/loading.gif" alt="Loading" />}
            </button>
            <button
              className="button is-fullwidth is-warning"
              type="button"
              disabled={disabled}
              onClick={onUserFinish}
            >
              Cancel
            </button>
          </div>
        </form>
      </article>
    </>
  );
};

export default LinkCasinoToUser;

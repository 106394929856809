import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Logo from './Logo';
import Background from './Background';
import FontSizeColor from './FontSizeColor';
import MinMaxColorPicker from './MinMaxColorPicker';
import ImageList from './ImageList';

import initialCasinoGroupState from 'services/context/initialCasinoGroupState';

import './index.css';

const CasinoDetails = ({ casino, onGroupSelect }) => {
  const [localCasino, setLocalCasino] = useState(casino);

  useEffect(() => {
    const { logo, background, colors, font } = initialCasinoGroupState;
    let modCasino = casino;

    if (!modCasino.logo) {
      modCasino = {
        ...modCasino,
        logo
      };
    }

    if (!modCasino.background) {
      modCasino = {
        ...modCasino,
        background
      };
    }

    if (!modCasino.colors) {
      modCasino = {
        ...modCasino,
        colors
      };
    }

    if (!modCasino.font) {
      modCasino = {
        ...modCasino,
        font
      };
    }

    setLocalCasino(modCasino);
  }, [casino]);

  return (
    <>
      <section className="app-images">
        <article>
          <p>
            <Link to={'#'} onClick={() => onGroupSelect(undefined)}>
              &lt; Go back to tables
            </Link>
          </p>
          <h1 className="title is-1">{localCasino.name}</h1>
        </article>
        <hr />
        <Logo casino={localCasino} />
        <hr />
        <Background casino={localCasino} />
        <hr />
        <FontSizeColor casino={localCasino} />
        <hr />
        <MinMaxColorPicker casino={localCasino} />
        <hr />
        <ImageList casino={localCasino} />
      </section>
    </>
  );
};

export default CasinoDetails;

import CRUD from './helpers/crud';
import AuthService from 'services/auth.service';

class UserCRUD extends CRUD {
  constructor() {
    super('users');
  }

  createUser = async user => {
    const { name, email, password, permissions, casinos } = user;

    const userResponse = await AuthService.createUser({ email, password });
    const userId = userResponse.uid;
    const userRecord = { name, email, permissions, casinos };
    return this.createWithCustomId(userId, userRecord);
  };

  getUserList = () => {
    return this.getAll();
  };

  updateUser = (id, body) => {
    return this.update(id, body);
  };

  deleteUser = async id => {
    await AuthService.deleteUser(id);
    return this.delete(id);
  };
}

export default new UserCRUD();

import initialState from './initialState';

function reducer(state, action) {
  // Log all global context actions in development mode
  if (process.env.NODE_ENV === 'development') {
    console.debug(action.type, action);
  }

  switch (action.type) {
    case 'LOAD_USER':
      return {
        ...state,
        user: action.payload
      };

    case 'LOAD_USERS_LIST':
      return {
        ...state,
        usersList: action.payload
      };
    case 'ADD_USER_TO_LIST':
      const usersList = [...state.usersList, action.payload];
      return {
        ...state,
        usersList
      };
    case 'UPDATE_USER_IN_LIST':
      const usersListWithModifiedElement = state.usersList.map(user => {
        if (user.uid === action.payload.uid) return action.payload;
        else return user;
      });
      return {
        ...state,
        usersList: usersListWithModifiedElement
      };
    case 'DELETE_USER_IN_LIST':
      const usersListWithRemovedElement = state.usersList.filter(
        user => user.uid !== action.payload.uid
      );
      return {
        ...state,
        usersList: usersListWithRemovedElement
      };

    case 'LOAD_CASINOS_LIST':
      return {
        ...state,
        casinosList: action.payload
      };
    case 'ADD_CASINO_TO_LIST':
      const casinoListWithNewElement = [...state.casinosList, action.payload];
      return {
        ...state,
        casinosList: casinoListWithNewElement
      };
    case 'UPDATE_CASINO_IN_LIST':
      const casinosListWithModifiedElement = state.casinosList.map(casino => {
        if (casino.uid === action.payload.uid) return action.payload;
        else return casino;
      });
      return {
        ...state,
        casinosList: casinosListWithModifiedElement
      };
    case 'DELETE_CASINO_IN_LIST':
      const casinosListWithRemovedElement = state.casinosList.filter(
        casino => casino.uid !== action.payload.uid
      );
      return {
        ...state,
        casinosList: casinosListWithRemovedElement
      };

    case 'RESTART_STATE':
      return initialState;
    default:
      return state;
  }
}

export default reducer;

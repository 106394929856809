import firebase from './helpers/firebase';
import CRUD from './helpers/crud';
import ImageStorage from './helpers/image.storage';

import CasinoService from './casino.service';
import initialCasinoGroupState from './context/initialCasinoGroupState';

class CasinoGroupCRUD extends CRUD {
  constructor() {
    super('casinos');

    this.AuthService = firebase.auth();
    this.imageStorage = new ImageStorage('casinos');
  }

  // Casino CRUD

  createCasino = (group, casinoId) => {
    group = {
      ...initialCasinoGroupState,
      name: group.name
    };

    return this.create(group).then(firebaseGroup => {
      return CasinoService.read(casinoId).then(casino => {
        if (!casino.groups) {
          casino.groups = [];
        }

        casino.groups = [...casino.groups, firebaseGroup.uid];

        return CasinoService.updateCasino(casinoId, { groups: casino.groups });
      });
    });
  };

  getCasinoList = () => {
    return this.getAll();
  };

  getCasino = id => {
    return this.read(id);
  };

  updateCasino = (id, body) => {
    return this.update(id, body);
  };

  deleteCasino = (id, casinoId) => {
    return this.delete(id).then(() => {
      return CasinoService.read(casinoId).then(casino => {
        casino.groups = casino.groups.filter(groupId => groupId !== id);

        return CasinoService.updateCasino(casinoId, { groups: casino.groups });
      });
    });
  };

  // Casino Images Crud
  uploadLogo = async (id, file) => {
    // Upload the image
    const nameArray = file.name.split('.');
    const logoExtension = nameArray[nameArray.length - 1];
    const logoName = `logo.${logoExtension}`;
    const logoUploadSnapshot = await this.imageStorage.createWithCustomName(
      id,
      file,
      logoName
    );
    const metadata = await logoUploadSnapshot.ref.getMetadata();
    const downloadURL = await logoUploadSnapshot.ref.getDownloadURL();

    const logo = {
      path: metadata.fullPath,
      url: downloadURL
    };

    await this.update(id, { logo });

    return logo;
  };

  uploadBackground = async (id, file) => {
    // Upload the image
    const backgroundExtension = file.name.split('.').slice(-1);
    const backgroundName = `logo.${backgroundExtension}`;
    const logoUploadSnapshot = await this.imageStorage.createWithCustomName(
      id,
      file,
      backgroundName
    );
    const metadata = await logoUploadSnapshot.ref.getMetadata();
    const downloadURL = await logoUploadSnapshot.ref.getDownloadURL();

    const background = {
      path: metadata.fullPath,
      url: downloadURL
    };

    await this.update(id, { background });

    return background;
  };

  uploadImages = (id, files) => {
    const videoDurationPromise = file => {
      return new Promise((resolve, reject) => {
        try {
          const videoElement = document.createElement('video');
          videoElement.preload = 'metadata';
          videoElement.src = URL.createObjectURL(file);

          videoElement.onloadedmetadata = () => {
            resolve(videoElement.duration);
          };
        } catch (e) {
          reject(e);
        }
      });
    };

    // Upload many images at once
    const imageUploadMap = files.map(file => {
      const DEFAULT_INTERVAL = 10;

      return this.imageStorage.create(id, file).then(async snapshot => {
        const metadata = await snapshot.ref.getMetadata();
        const downloadURL = await snapshot.ref.getDownloadURL();
        const type = file.type.indexOf('video') === 0 ? 'video' : 'photo';
        let interval;

        if (type === 'video') {
          interval = await videoDurationPromise(file);
        } else {
          interval = DEFAULT_INTERVAL;
        }

        return {
          type,
          interval,
          metadata,
          downloadURL
        };
      });
    });

    // Updates and returns the updated casino property
    return Promise.all(imageUploadMap).then(uploadedImages => {
      const transactionRef = this.service.collection(this.collection).doc(id);

      return this.service.runTransaction(transaction => {
        return transaction.get(transactionRef).then(doc => {
          if (!doc.exists) return;

          const newImages = doc.get('images');
          const images = uploadedImages.map(image => {
            return {
              type: image.type,
              interval: image.interval,
              path: image.metadata.fullPath,
              url: image.downloadURL
            };
          });
          newImages.push(...images);

          transaction.update(transactionRef, 'images', newImages);

          return newImages;
        });
      });
    });
  };

  deleteImage = (id, imagePath) => {
    return this.imageStorage.delete(imagePath).then(() => {
      const transactionRef = this.service.collection(this.collection).doc(id);

      return this.service.runTransaction(transaction => {
        return transaction.get(transactionRef).then(doc => {
          if (!doc.exists) return;

          const oldImages = doc.get('images');
          const newImages = oldImages.filter(image => image.path !== imagePath);

          transaction.update(transactionRef, 'images', newImages);

          return newImages;
        });
      });
    });
  };
}

export default new CasinoGroupCRUD();

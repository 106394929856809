import React, { useState, useContext } from 'react';

import WelcomeHeader from 'blocks/WelcomeHeader';
import Modal from 'blocks/Modal';

import UserModal from './UserModal';
import UserList from './UserList';

import AppContext from 'services/context';

import './index.css';

const UserPage = () => {
  const { state } = useContext(AppContext);
  const { user } = state;

  const [userModal, setUserModal] = useState(false);

  return (
    <>
      <WelcomeHeader activePage="users" user={user} />
      <UserList />

      <button
        className="button is-success"
        type="button"
        onClick={() => setUserModal(true)}
      >
        Add user
      </button>

      {userModal && (
        <Modal closeModal={() => setUserModal(false)}>
          <UserModal onUserFinish={() => setUserModal(false)} />
        </Modal>
      )}
    </>
  );
};

export default UserPage;

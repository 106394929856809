import * as sentry from '@sentry/browser';

const isProduction = process.env.NODE_ENV === 'production';

if (isProduction) {
  sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DATA_SOURCE_NAME
  });
}

export default sentry;

import React, { useState, useContext } from 'react';
import { SwatchesPicker } from 'react-color';

import AppContext from 'services/context';
import CasinoGroupService from 'services/casino-group.service';

import './MinMaxColorPicker.css';

const MinMaxColorPickerRow = ({
  label,
  backgroundColor,
  foregroundColor,
  onColorsChange
}) => {
  const [background, setBackground] = useState(backgroundColor);
  const [foreground, setForeground] = useState(foregroundColor);

  const updateColor = (type, color) => {
    if (type === 'background') {
      setBackground(color);
    } else if (type === 'foreground') {
      setForeground(color);
    }

    onColorsChange({
      background,
      foreground,
      [type]: color
    });
  };

  return (
    <tr>
      <td>
        <SwatchesPicker
          color={background}
          onChangeComplete={color => updateColor('background', color.hex)}
        />
      </td>
      <td>
        <SwatchesPicker
          color={foreground}
          onChangeComplete={color => updateColor('foreground', color.hex)}
        />
      </td>
      <td
        className="button-example has-text-centered is-capitalized has-text-weight-bold"
        style={{ backgroundColor: background, color: foreground }}
      >
        {label}
      </td>
    </tr>
  );
};

const MinMaxColorPicker = ({ casino }) => {
  const { dispatch } = useContext(AppContext);

  const [notification, setNotification] = useState(null);

  const { uid } = casino;

  const updateCasinoColors = (type, colors) => {
    const body = {
      colors: {
        ...casino.colors,
        [type]: colors
      }
    };

    CasinoGroupService.updateCasino(uid, body)
      .then(casino => {
        dispatch({
          type: 'UPDATE_CASINO_IN_LIST',
          payload: casino
        });
      })
      .catch(err => {
        setNotification(err.message);
      });
  };

  if (!casino.colors) return null;

  return (
    <>
      <article>
        <h2 className="subtitle">Buttons</h2>
        {notification && (
          <p className="notification is-warning">{notification}</p>
        )}
        <table className="table is-bordered">
          <thead>
            <tr>
              <th>Background color</th>
              <th>Foreground color</th>
              <th>Preview</th>
            </tr>
          </thead>
          <tbody>
            <MinMaxColorPickerRow
              label="Min"
              backgroundColor={casino.colors.min.background}
              foregroundColor={casino.colors.min.foreground}
              onColorsChange={colors => updateCasinoColors('min', colors)}
            />
            <MinMaxColorPickerRow
              label="Max"
              backgroundColor={casino.colors.max.background}
              foregroundColor={casino.colors.max.foreground}
              onColorsChange={colors => updateCasinoColors('max', colors)}
            />
          </tbody>
        </table>
      </article>
    </>
  );
};

export default MinMaxColorPicker;

import React, { useState, useContext } from 'react';

import Modal from 'blocks/Modal';

import UserModal from './UserModal';
import DeleteUser from './DeleteUser';
import LinkCasinoToUser from './LinkCasinoToUser';

import AppContext from 'services/context';

import './UserList.css';

const UserRow = ({ user, editUser, deleteUser, linkCasinos }) => {
  const { state } = useContext(AppContext);
  const { casinosList } = state;

  const permissions = user.permissions.admin ? 'Administrator' : 'User';

  const casinos = casinosList
    .filter(casino => user.casinos.includes(casino.uid))
    .map(casino => (
      <span key={casino.uid} className="tag is-info">
        {casino.name}
      </span>
    ));

  return (
    <tr>
      <td>{user.name}</td>
      <td>{user.email}</td>
      <td>
        {casinos}
        <button
          className="button is-small is-primary"
          type="button"
          onClick={() => linkCasinos(user)}
        >
          Manage
        </button>
      </td>
      <td>{permissions}</td>
      <td>
        <button
          className="button is-link"
          type="button"
          onClick={() => editUser(user)}
        >
          Edit
        </button>
        <button
          className="button is-danger"
          type="button"
          onClick={() => deleteUser(user)}
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

const UserList = () => {
  const { state } = useContext(AppContext);
  const { usersList } = state;

  const [editUser, setEditUser] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [linkCasinosToUser, setLinkCasinosToUser] = useState(false);

  return (
    <>
      <table className="table is-bordered is-hoverable is-fullwidth">
        <thead>
          <tr>
            <td>Name</td>
            <td>Email</td>
            <td>Casinos</td>
            <td>Permissions</td>
            <td>Options</td>
          </tr>
        </thead>
        <tbody>
          {usersList.map(user => (
            <UserRow
              key={user.uid}
              user={user}
              editUser={setEditUser}
              deleteUser={setDeleteUser}
              linkCasinos={setLinkCasinosToUser}
            />
          ))}
        </tbody>
      </table>

      {editUser && (
        <Modal closeModal={() => setEditUser(false)}>
          <UserModal user={editUser} onUserFinish={() => setEditUser(false)} />
        </Modal>
      )}

      {deleteUser && (
        <Modal closeModal={() => setDeleteUser(false)}>
          <DeleteUser
            user={deleteUser}
            onUserFinish={() => setDeleteUser(false)}
          />
        </Modal>
      )}

      {linkCasinosToUser && (
        <Modal closeModal={() => setLinkCasinosToUser(false)}>
          <LinkCasinoToUser
            user={linkCasinosToUser}
            onUserFinish={() => setLinkCasinosToUser(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default UserList;

import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import HeaderImage from 'blocks/HeaderImage';

import History from 'services/history';
import AppContext from 'services/context';
import AuthService from 'services/auth.service';

import './index.css';

const Login = () => {
  const { state, dispatch } = useContext(AppContext);
  const { user } = state;

  const [disabled, setDisabled] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    if (user) {
      History.push('/dashboard');
    }
  }, [user]);

  const handleForm = e => {
    e.preventDefault();

    setDisabled(true);

    AuthService.login(email, password)
      .then(user => {
        dispatch({
          type: 'LOAD_USER',
          payload: user
        });

        History.push('/dashboard');
      })
      .catch(err => {
        setDisabled(false);

        setNotification(err.message);
      });
  };

  return (
    <>
      <HeaderImage />
      <section className="app-login__form-section">
        <div>
          {notification && (
            <article className="app-login__form-panel">
              <p className="notification is-warning app-login__form-panel--notification">
                {notification}
              </p>
            </article>
          )}
          <article className="panel app-login__form-panel">
            <div className="panel-heading">Login form</div>

            <form onSubmit={handleForm}>
              <div className="panel-block">
                <label className="label app-login__form-field">
                  Email address:
                  <input
                    className="input"
                    type="text"
                    required
                    value={email}
                    onChange={({ target }) => setEmail(target.value)}
                  />
                </label>
              </div>
              <div className="panel-block">
                <label className="label app-login__form-field">
                  Password:
                  <input
                    className="input"
                    type="password"
                    required
                    value={password}
                    onChange={({ target }) => setPassword(target.value)}
                  />
                </label>
              </div>
              <div className="panel-block">
                <button
                  className="button is-fullwidth is-link"
                  type="submit"
                  disabled={disabled}
                >
                  Login
                  {disabled && <img src="/images/loading.gif" alt="Loading" />}
                </button>
              </div>
            </form>
          </article>
          <article className="app-login__reset-link">
            <Link to="/reset-password">Click here to reset your password</Link>
          </article>
        </div>
      </section>
    </>
  );
};

export default Login;

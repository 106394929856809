import firebase from './helpers/firebase';
import axios from 'axios';

class Auth {
  service = firebase.auth();
  dbService = firebase.firestore();
  endpoint = process.env.REACT_APP_FIREBASE_ENDPOINT;
  idToken = null;

  login(email, password) {
    return this.service
      .signInWithEmailAndPassword(email, password)
      .then(response => response.user)
      .then(user => this.getUserInfo(user));
  }

  logout() {
    return this.service.signOut();
  }

  getUserToken = async () => {
    if (this.idToken === null) {
      this.idToken = await this.service.currentUser.getIdToken(true);
    }

    return this.idToken;
  };

  checkAuth(cb) {
    return this.service.onAuthStateChanged(user => {
      if (user) {
        this.getUserInfo(user).then(cb);
      } else {
        cb(null);
      }
    });
  }

  getUserInfo(user) {
    const userRef = `/users/${user.uid}`;

    return this.dbService
      .doc(userRef)
      .get()
      .then(doc => doc.data());
  }

  resetPassword(email) {
    return this.service.sendPasswordResetEmail(email);
  }

  changePassword(password) {
    return this.service.currentUser.updatePassword(password);
  }

  createUser = async user => {
    const idToken = await this.getUserToken();

    return axios
      .post(`${this.endpoint}/createUser`, user, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + idToken
        }
      })
      .then(response => response.data);
  };

  deleteUser = async userId => {
    const idToken = await this.getUserToken();

    return axios
      .delete(`${this.endpoint}/deleteUser/${userId}`, {
        headers: {
          Authorization: 'Bearer ' + idToken
        }
      })
      .then(response => response.data);
  };
}

export default new Auth();

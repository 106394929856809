import React from 'react';

import './index.css';

const LoadingScreen = () => {
  return (
    <>
      <section className="loading-screen">
        <div className="loading-animation">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </section>
    </>
  );
};

export default LoadingScreen;

import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import HeaderImage from 'blocks/HeaderImage';

import History from 'services/history';
import AppContext from 'services/context';
import AuthService from 'services/auth.service';

import './index.css';

const ResetPassword = () => {
  const { state } = useContext(AppContext);
  const { user } = state;

  const [disabled, setDisabled] = useState(false);

  const [email, setEmail] = useState('');
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    if (user) {
      History.push('/dashboard');
    }
  }, [user]);

  const handleForm = e => {
    e.preventDefault();

    setDisabled(true);

    AuthService.resetPassword(email)
      .then(() => {
        setDisabled(false);

        setNotification('Password recovery instructions sent to your email.');
      })
      .catch(err => {
        setDisabled(false);

        setNotification(err.message);
      });
  };

  return (
    <>
      <HeaderImage />
      <section className="app-reset-password__form-section">
        <div>
          {notification && (
            <article className="app-reset-password__form-panel">
              <p className="notification is-warning app-reset-password__form-panel--notification">
                {notification}
              </p>
            </article>
          )}
          <article className="panel app-reset-password__form-panel">
            <div className="panel-heading">Reset password</div>

            <form onSubmit={handleForm}>
              <div className="panel-block">
                <label className="label app-reset-password__form-field">
                  Email address:
                  <input
                    className="input"
                    type="text"
                    required
                    value={email}
                    onChange={({ target }) => setEmail(target.value)}
                  />
                </label>
              </div>
              <div className="panel-block">
                <button
                  className="button is-fullwidth is-link"
                  type="submit"
                  disabled={disabled}
                >
                  Send password recovery email
                  {disabled && <img src="/images/loading.gif" alt="Loading" />}
                </button>
              </div>
            </form>
          </article>
          <article className="app-reset-password__reset-link">
            <Link to="/login">Go back to login page</Link>
          </article>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;

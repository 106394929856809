import React, { useState, useContext } from 'react';
import { SwatchesPicker } from 'react-color';

import AppContext from 'services/context';
import CasinoGroupService from 'services/casino-group.service';

import './FontSizeColor.css';

const FontSizeColor = ({ casino }) => {
  const { dispatch } = useContext(AppContext);

  const [notification, setNotification] = useState(null);

  const { uid } = casino;

  const updateCasinoFont = (type, value) => {
    const body = {
      font: {
        ...casino.font,
        [type]: value
      }
    };

    CasinoGroupService.updateCasino(uid, body)
      .then(casino => {
        dispatch({
          type: 'UPDATE_CASINO_IN_LIST',
          payload: casino
        });
      })
      .catch(err => {
        setNotification(err.message);
      });
  };

  if (!casino.font) return null;

  // Fonts from 14px to 100px
  const fontSizes = new Array(44).fill(undefined).map((_, n) => {
    const FONT_BASE = 14;
    const font = String(FONT_BASE + n * 2);

    return (
      <option key={n} value={font}>
        {font}
      </option>
    );
  });

  const previewStyle = {
    ...casino.font,
    fontSize: casino.font.fontSize + 'px',
    backgroundImage: `url(${casino.background.url})`,
    backgroundSize: 'cover',
    textAlign: 'center',
    width: '100%'
  };

  return (
    <>
      <article>
        {notification && (
          <p className="notification is-warning">{notification}</p>
        )}
        <h2 className="subtitle">Font size and color</h2>
        <table className="table is-bordered is-fullwidth">
          <thead>
            <tr>
              <th>Font size</th>
              <th>Font color</th>
              <th>Preview</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="select">
                  <select
                    value={casino.font.fontSize}
                    onChange={({ target }) =>
                      updateCasinoFont('fontSize', target.value)
                    }
                  >
                    {fontSizes}
                  </select>
                </div>
              </td>
              <td>
                <SwatchesPicker
                  color={casino.font.color}
                  onChangeComplete={color =>
                    updateCasinoFont('color', color.hex)
                  }
                />
              </td>
              <td style={previewStyle}>Header text example</td>
            </tr>
          </tbody>
        </table>
      </article>
    </>
  );
};

export default FontSizeColor;

import React, { useState, useContext, useEffect } from 'react';

import AppContext from 'services/context';
import UserService from 'services/user.service';

import './UserModal.css';

const UserModal = ({ user, onUserFinish }) => {
  const { dispatch } = useContext(AppContext);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const [isUserAdmin, setIsUserAdmin] = useState(false);

  const [disabled, setDisabled] = useState(false);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    if (user) {
      setName(user.name);
      setIsUserAdmin(user.permissions.admin);
    }
  }, [user]);

  const userValidation = () => {
    if (!user && (email === '' || password === '' || repassword === '')) {
      setNotification('Please check the information provided.');
      return false;
    } else if (!user && password.length < 8) {
      setNotification('Passwords should be at least 8 characters long.');
      return false;
    } else if (!user && password !== repassword) {
      setNotification("Passwords don't match.");
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (userValidation()) {
      setDisabled(true);

      const userObject = user
        ? {
            name,
            permissions: {
              admin: isUserAdmin
            }
          }
        : {
            name,
            email,
            password,
            permissions: {
              admin: isUserAdmin
            },
            casinos: []
          };

      const createOrUpdate = user
        ? UserService.updateUser(user.uid, userObject)
        : UserService.createUser(userObject);

      const actionType = user ? 'UPDATE_USER_IN_LIST' : 'ADD_USER_TO_LIST';

      createOrUpdate
        .then(user => {
          dispatch({
            type: actionType,
            payload: user
          });

          onUserFinish();
        })
        .catch(err => {
          setDisabled(false);
          setNotification(err.message);
        });
    }
  };
  const title = user ? 'Edit user' : 'Create user';

  return (
    <>
      <section className="app-add-user__form-section">
        <div>
          {notification && (
            <article className="app-add-user__form-panel">
              <p className="notification is-warning app-add-user__form-panel--notification">
                {notification}
              </p>
            </article>
          )}

          <article className="panel app-add-user__form-panel">
            <div className="panel-heading">{title}</div>

            <form onSubmit={handleSubmit}>
              <div className="panel-block">
                <label className="label app-add-user__form-field">
                  Name:
                  <input
                    className="input"
                    type="text"
                    required
                    value={name}
                    onChange={({ target }) => setName(target.value)}
                  />
                </label>
              </div>
              {!user && (
                <>
                  <div className="panel-block">
                    <label className="label app-add-user__form-field">
                      Email:
                      <input
                        className="input"
                        type="email"
                        required
                        value={email}
                        onChange={({ target }) => setEmail(target.value)}
                      />
                    </label>
                  </div>
                  <div className="panel-block">
                    <label className="label app-add-user__form-field">
                      Password:
                      <input
                        className="input"
                        type="password"
                        required
                        value={password}
                        onChange={({ target }) => setPassword(target.value)}
                      />
                    </label>
                  </div>
                  <div className="panel-block">
                    <label className="label app-add-user__form-field">
                      Re-type Password:
                      <input
                        className="input"
                        type="password"
                        required
                        value={repassword}
                        onChange={({ target }) => setRepassword(target.value)}
                      />
                    </label>
                  </div>
                </>
              )}
              <div className="panel-block">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    checked={isUserAdmin}
                    onChange={({ target }) => setIsUserAdmin(target.checked)}
                  />
                  Administrator
                </label>
              </div>
              <div className="panel-block">
                <button
                  className="button is-fullwidth is-link"
                  type="submit"
                  disabled={disabled}
                >
                  {title}
                  {disabled && <img src="/images/loading.gif" alt="Loading" />}
                </button>
                <button
                  className="button is-fullwidth is-warning"
                  type="button"
                  disabled={disabled}
                  onClick={onUserFinish}
                >
                  Cancel
                </button>
              </div>
            </form>
          </article>
        </div>
      </section>
    </>
  );
};

export default UserModal;

import React, { useState, useEffect, useContext } from 'react';
import { Route } from 'react-router';

import NavBar from 'blocks/Navbar';
import HeaderImage from 'blocks/HeaderImage';
import Content from 'blocks/Content';
import LoadingScreen from 'blocks/LoadingScreen';

import UserPage from './user';
import CasinoPage from './casino';
import CasinoGroups from './casino-groups';
import CasinoList from './casino-list';

import History from 'services/history';
import AppContext from 'services/context';
import AuthService from 'services/auth.service';
import CasinoService from 'services/casino.service';
import UserService from 'services/user.service';

import './index.css';

const Dashboard = () => {
  const { state, dispatch } = useContext(AppContext);
  const { user, casinosList } = state;

  const [isAdmin, setIsAdmin] = useState(false);

  // Loads user information
  useEffect(() => {
    if (user === undefined) {
      AuthService.checkAuth(user => {
        dispatch({
          type: 'LOAD_USER',
          payload: user
        });

        if (!user) {
          History.push('/login');
        }
      });
    } else if (user && user.permissions) {
      setIsAdmin(user.permissions.admin);
    }
  }, [user, dispatch]);

  // Loads casinos when component loads
  useEffect(() => {
    if (casinosList.length === 0) {
      CasinoService.getCasinoList().then(casinos => {
        dispatch({
          type: 'LOAD_CASINOS_LIST',
          payload: casinos
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Loads user list if user is admin
  useEffect(() => {
    if (isAdmin) {
      UserService.getUserList().then(users => {
        dispatch({
          type: 'LOAD_USERS_LIST',
          payload: users
        });
      });
    }
  }, [isAdmin, dispatch]);

  if (user === undefined) return <LoadingScreen />;

  return (
    <>
      <NavBar />
      <HeaderImage />
      <Content>
        {isAdmin ? (
          <>
            <Route
              exact
              path="(/dashboard|/dashboard/casinos)"
              component={CasinoPage}
            />
            <Route exact path="/dashboard/users" component={UserPage} />
          </>
        ) : (
          <>
            <Route
              exact
              path="(/dashboard|/dashboard/list)"
              component={CasinoList}
            />
          </>
        )}

        <Route path="/dashboard/details/:uid" component={CasinoGroups} />
      </Content>
    </>
  );
};

export default Dashboard;

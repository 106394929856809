import React, { useState, useContext, useEffect } from 'react';

import NavBar from 'blocks/Navbar';
import HeaderImage from 'blocks/HeaderImage';

import AppContext from 'services/context';
import History from 'services/history';
import AuthService from 'services/auth.service';

import './index.css';

const ChangePassword = () => {
  const { state } = useContext(AppContext);
  const { user } = state;

  useEffect(() => {
    if (!user) {
      History.push('/login');
    }
  }, [user]);

  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const [notification, setNotification] = useState(null);

  const [disabled, setDisabled] = useState(false);

  const passwordValidation = () => {
    if (password.length < 8) {
      setNotification('Passwords should be at least 8 characters long.');
      return false;
    } else if (password !== repassword) {
      setNotification("Passwords don't match.");
      return false;
    } else {
      return true;
    }
  };

  const handleForm = e => {
    e.preventDefault();

    if (passwordValidation()) {
      setDisabled(true);

      AuthService.changePassword(password)
        .then(() => {
          setDisabled(false);

          setNotification('Your password has been updated.');
        })
        .catch(err => {
          setDisabled(false);

          setNotification(err.message);
        });
    }
  };

  return (
    <>
      <NavBar />
      <HeaderImage />

      <section className="app-change-password__form-section">
        <div>
          {notification && (
            <article className="app-change-password__form-panel">
              <p className="notification is-warning app-change-password__form-panel--notification">
                {notification}
              </p>
            </article>
          )}
          <article className="panel app-change-password__form-panel">
            <div className="panel-heading">Change Password</div>

            <form onSubmit={handleForm}>
              <div className="panel-block">
                <label className="label app-change-password__form-field">
                  New password:
                  <input
                    className="input"
                    type="password"
                    required
                    value={password}
                    onChange={({ target }) => setPassword(target.value)}
                  />
                </label>
              </div>
              <div className="panel-block">
                <label className="label app-change-password__form-field">
                  Re-type new password:
                  <input
                    className="input"
                    type="password"
                    required
                    value={repassword}
                    onChange={({ target }) => setRepassword(target.value)}
                  />
                </label>
              </div>
              <div className="panel-block">
                <button
                  className="button is-fullwidth is-success"
                  type="submit"
                  disabled={disabled}
                >
                  Change password
                  {disabled && <img src="/images/loading.gif" alt="Loading" />}
                </button>
              </div>
            </form>
          </article>
        </div>
      </section>
    </>
  );
};

export default ChangePassword;

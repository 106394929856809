import React, { useState, useContext, useEffect } from 'react';

import Modal from 'blocks/Modal';
import DeleteImage from './DeleteImage';

import AppContext from 'services/context';
import CasinoGroupService from 'services/casino-group.service';

import './ImageList.css';

const ImageRow = ({ image, deleteImage, updateImageInterval }) => {
  const [interval, setInterval] = useState(image.interval);

  const updateInterval = ({ target }) => {
    setInterval(+target.value);
    updateImageInterval(+target.value);
  };

  const isVideo = image.type === 'video';

  return (
    <tr>
      <td className="image-cell">
        {isVideo ? (
          <video src={image.url} preload="metadata" controls="controls"></video>
        ) : (
          <img src={image.url} alt="Casino screen" />
        )}
      </td>
      <td>
        {isVideo ? (
          <span>{parseInt(interval, 10)}</span>
        ) : (
          <input
            className="input"
            placeholder="Interval"
            type="number"
            min="3"
            value={interval}
            onChange={updateInterval}
          />
        )}
      </td>
      <td>
        <button className="button is-danger" onClick={() => deleteImage(image)}>
          Delete
        </button>
      </td>
    </tr>
  );
};

const ImageList = ({ casino }) => {
  const { dispatch } = useContext(AppContext);

  const [notification, setNotification] = useState(null);
  const [imageFiles, setImageFiles] = useState([]);
  const [deleteImage, setDeleteImage] = useState(false);
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const { uid } = casino;

  useEffect(() => {
    if (imageFiles.length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [imageFiles]);

  const handleImageUpload = e => {
    e.preventDefault();

    setLoading(true);
    setDisabled(true);
    setUploadDisabled(true);
    if (imageFiles.length > 0) {
      CasinoGroupService.uploadImages(uid, imageFiles)
        .then(images => {
          dispatch({
            type: 'UPDATE_CASINO_IN_LIST',
            payload: {
              ...casino,
              images
            }
          });

          setLoading(false);
          setDisabled(false);
          setUploadDisabled(false);
          setImageFiles([]);
        })
        .catch(err => {
          setLoading(false);
          setDisabled(false);
          setUploadDisabled(false);

          setNotification(err.message);
        });
    }
  };

  const updateImageInterval = (index, value) => {
    const image = casino.images[index];
    const imagesToUpdate = [...casino.images];
    imagesToUpdate[index] = {
      ...image,
      interval: +value
    };
    const updatedCasino = {
      ...casino,
      images: imagesToUpdate
    };

    CasinoGroupService.update(uid, updatedCasino)
      .then(_ => {
        dispatch({
          type: 'UPDATE_CASINO_IN_LIST',
          payload: updatedCasino
        });

        setLoading(false);
        setDisabled(false);
      })
      .catch(err => {
        setLoading(false);
        setDisabled(false);

        setNotification(err.message);
      });
  };

  const casinoHasImages = casino.images && casino.images.length > 0;

  const images =
    casinoHasImages &&
    casino.images.map((image, index) => (
      <ImageRow
        key={index}
        image={image}
        deleteImage={setDeleteImage}
        updateImageInterval={value => updateImageInterval(index, value)}
      />
    ));

  return (
    <>
      <article>
        <h2 className="subtitle">Videos and Images</h2>
        <div>
          {casinoHasImages && (
            <table className="table">
              <thead>
                <tr>
                  <th>Preview</th>
                  <th>Duration</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>{images}</tbody>
            </table>
          )}
        </div>
        <form onSubmit={handleImageUpload}>
          {notification && (
            <p className="notification is-warning">{notification}</p>
          )}
          <div className="field">
            <div className="file is-boxed is-info has-name">
              <label className="file-label">
                <input
                  className="file-input"
                  type="file"
                  accept=".jpg,.jpeg,.png,.gif,.bmp,.mp4"
                  multiple
                  disabled={uploadDisabled}
                  onChange={({ target }) => setImageFiles([...target.files])}
                />
                <span className="file-cta">
                  <span className="file-label">Click here to upload</span>
                </span>
                <span className="file-name has-text-centered">
                  {imageFiles.length} images selected
                </span>
              </label>
            </div>
          </div>

          <button
            className="button is-primary"
            type="submit"
            disabled={disabled}
          >
            Upload Images
            {loading && <img src="/images/loading.gif" alt="Loading" />}
          </button>
        </form>
      </article>

      {deleteImage && (
        <Modal closeModal={() => setDeleteImage(false)}>
          <DeleteImage
            casino={casino}
            image={deleteImage}
            onImageFinish={() => setDeleteImage(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default ImageList;

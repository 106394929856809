import React from 'react';
import { Redirect, Route } from 'react-router';
import { Router, Switch } from 'react-router-dom';

import Login from 'pages/login';
import ResetPassword from 'pages/reset-password';
import ChangePassword from 'pages/change-password';
import Dashboard from 'pages/dashboard';

import { AppContextProvider } from 'services/context';
import History from 'services/history';

import './App.css';

function App() {
  return (
    <Router history={History}>
      <Switch>
        <AppContextProvider>
          <Route exact path="/login" component={Login} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/change-password" component={ChangePassword} />
          <Route path="/dashboard" component={Dashboard} />
          <Redirect strict from="/" to="/dashboard" />
        </AppContextProvider>
      </Switch>
    </Router>
  );
}

export default App;

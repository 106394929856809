import React, { useState, useContext } from 'react';

import ConfirmDialog from 'blocks/ConfirmDialog';

import AppContext from 'services/context';
import CasinoGroupService from 'services/casino-group.service';

const DeleteImageComponent = ({ casino, image, onImageFinish }) => {
  const { dispatch } = useContext(AppContext);
  const { uid } = casino;
  const { path } = image;

  const [notification, setNotification] = useState('');

  const deleteImage = () => {
    CasinoGroupService.deleteImage(uid, path)
      .then(images => {
        dispatch({
          type: 'UPDATE_CASINO_IN_LIST',
          payload: {
            ...casino,
            images
          }
        });

        onImageFinish();
      })
      .catch(err => {
        setNotification(err.message);
      });
  };

  return (
    <>
      {notification && (
        <article className="panel">
          <p className="notification is-warning app-login__form-panel--notification">
            {notification}
          </p>
        </article>
      )}

      <ConfirmDialog
        title="Delete Image"
        text="Are you sure you want to delete this image?"
        onConfirm={deleteImage}
        onCancel={onImageFinish}
      />
    </>
  );
};

export default DeleteImageComponent;

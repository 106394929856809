import React from 'react';

const Modal = ({ children, closeModal }) => {
  return (
    <>
      <div className="modal is-clipped is-active">
        <div className="modal-background" />
        <div className="modal-content">{children}</div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={closeModal}
        />
      </div>
    </>
  );
};

export default Modal;

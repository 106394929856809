import React, { useState, useContext } from 'react';

import ConfirmDialog from 'blocks/ConfirmDialog';

import AppContext from 'services/context';
import UserService from 'services/user.service';

const DeleteUserComponent = ({ user, onUserFinish }) => {
  const { dispatch } = useContext(AppContext);

  const [notification, setNotification] = useState('');

  const deleteUser = () => {
    UserService.deleteUser(user.uid)
      .then(user => {
        dispatch({
          type: 'DELETE_USER_IN_LIST',
          payload: user
        });

        onUserFinish();
      })
      .catch(err => {
        setNotification(err.message);
      });
  };

  return (
    <>
      {notification && (
        <article className="panel">
          <p className="notification is-warning app-login__form-panel--notification">
            {notification}
          </p>
        </article>
      )}

      <ConfirmDialog
        title="Delete User"
        text="Are you sure you want to delete this user?"
        onConfirm={deleteUser}
        onCancel={onUserFinish}
      />
    </>
  );
};

export default DeleteUserComponent;

import firebase from './firebase';

class CRUD {
  constructor(collection) {
    this.collection = collection;
    this.service = firebase.firestore();
  }

  create(body) {
    return this.service
      .collection(this.collection)
      .add(body)
      .then(doc => doc.get())
      .then(prepareDocument);
  }

  createWithCustomId = async (id, body) => {
    await this.service
      .collection(this.collection)
      .doc(id)
      .set(body);

    return this.read(id);
  };

  read(id) {
    return this.service
      .collection(this.collection)
      .doc(id)
      .get()
      .then(prepareDocument);
  }

  getAll() {
    return this.service
      .collection(this.collection)
      .get()
      .then(colRef => colRef.docs.map(prepareDocument));
  }

  update = async (id, body) => {
    await this.service
      .collection(this.collection)
      .doc(id)
      .set(body, { merge: true });

    return this.read(id);
  };

  delete = async id => {
    const record = await this.read(id);

    return this.service
      .collection(this.collection)
      .doc(id)
      .delete()
      .then(() => {
        return record;
      });
  };
}

export default CRUD;

function prepareDocument(docRef) {
  const docData = docRef.data();

  return {
    ...docData,
    uid: docRef.id
  };
}

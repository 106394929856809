const initialCasinoGroupState = {
  name: 'Casino name',
  logo: {
    url: '/images/logo.png'
  },
  background: {
    url: '/images/screen-without-logo.png'
  },
  colors: {
    min: {
      background: 'rgb(0, 0, 0)',
      foreground: 'rgb(255, 255, 255)'
    },
    max: {
      background: 'rgb(255, 235, 59)',
      foreground: 'rgb(0, 0, 0)'
    }
  },
  font: {
    fontSize: 40,
    color: 'rgb(255, 255, 255)'
  },
  images: []
};

export default initialCasinoGroupState;

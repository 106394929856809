import React from 'react';
import { Link } from 'react-router-dom';

import './index.css';

const WelcomeHeader = ({ activePage, user, tabs = true }) => {
  const casinosTabClass = activePage === 'casinos' ? 'is-active' : '';
  const usersTabClass = activePage === 'users' ? 'is-active' : '';

  return (
    <>
      <section className="app__welcome-header">
        <h2 className="subtitle">Welcome {user && user.name}</h2>
      </section>
      {tabs && (
        <section className="tabs is-boxed">
          <ul>
            <li className={casinosTabClass}>
              <Link to="/dashboard/casinos">Casinos</Link>
            </li>
            <li className={usersTabClass}>
              <Link to="/dashboard/users">Users</Link>
            </li>
          </ul>
        </section>
      )}
    </>
  );
};

export default WelcomeHeader;

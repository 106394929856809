import CRUD from './helpers/crud';
import CasinoGroupService from './casino-group.service';

class CasinoCRUD extends CRUD {
  constructor() {
    super('casinos-v2');
  }

  // Casino CRUD

  createCasino = casino => {
    return this.create(casino);
  };

  getCasinoList = () => {
    return this.getAll();
  };

  getCasino = id => {
    return this.read(id).then(casino => {
      const casinoGroups = casino.groups.map(groupId =>
        CasinoGroupService.read(groupId)
      );

      return Promise.all(casinoGroups).then(groups => {
        return {
          ...casino,
          groups
        };
      });
    });
  };

  updateCasino = (id, body) => {
    return this.update(id, body);
  };

  deleteCasino = id => {
    // TODO: Remove groups and assets
    return this.delete(id);
  };
}

export default new CasinoCRUD();

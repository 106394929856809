import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import Modal from 'blocks/Modal';

import CasinoModal from './CasinoModal';
import DeleteCasino from './DeleteCasino';

import AppContext from 'services/context';

import './CasinoList.css';

const CasinoRow = ({ casino, editCasino, deleteCasino }) => {
  return (
    <tr>
      <td>
        <Link to={`/dashboard/details/${casino.uid}`}>{casino.name}</Link>
      </td>
      <td>
        <button
          className="button is-link"
          type="button"
          onClick={() => {
            editCasino(casino);
          }}
        >
          Edit
        </button>
        <button
          className="button is-danger"
          type="button"
          onClick={() => {
            deleteCasino(casino);
          }}
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

const CasinoRowsEmpty = () => {
  return (
    <tr>
      <td colSpan={2}>There are not casinos. You can add one below.</td>
    </tr>
  );
};

const CasinoList = () => {
  const { state } = useContext(AppContext);
  const { casinosList } = state;

  const [editCasino, setEditCasino] = useState(false);
  const [deleteCasino, setDeleteCasino] = useState(false);

  const casinoRows = casinosList.map(casino => (
    <CasinoRow
      key={casino.uid}
      casino={casino}
      editCasino={setEditCasino}
      deleteCasino={setDeleteCasino}
    />
  ));

  return (
    <>
      <table className="table is-bordered is-hoverable is-fullwidth">
        <thead>
          <tr>
            <td>Name</td>
            <td>Options</td>
          </tr>
        </thead>
        <tbody>
          {casinoRows.length > 0 ? casinoRows : <CasinoRowsEmpty />}
        </tbody>
      </table>

      {editCasino && (
        <Modal closeModal={() => setEditCasino(false)}>
          <CasinoModal
            casino={editCasino}
            onCasinoFinish={() => setEditCasino(false)}
          />
        </Modal>
      )}

      {deleteCasino && (
        <Modal closeModal={() => setDeleteCasino(false)}>
          <DeleteCasino
            casino={deleteCasino}
            onCasinoFinish={() => setDeleteCasino(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default CasinoList;

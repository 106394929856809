import React, { useState, useContext } from 'react';

import ConfirmDialog from 'blocks/ConfirmDialog';

import AppContext from 'services/context';
import CasinoService from 'services/casino.service';
import CasinoGroupService from 'services/casino-group.service';

const DeleteCasinoComponent = ({
  type = 'casino',
  casinoId,
  casino,
  onCasinoFinish
}) => {
  const { dispatch } = useContext(AppContext);

  const [notification, setNotification] = useState('');

  const deleteCasino = () => {
    const service = type === 'casino' ? CasinoService : CasinoGroupService;

    service
      .deleteCasino(casino.uid, casinoId)
      .then(casino => {
        dispatch({
          type: 'DELETE_CASINO_IN_LIST',
          payload: casino
        });

        onCasinoFinish();
      })
      .catch(err => {
        setNotification(err.message);
      });
  };

  const typeName = type === 'casino' ? 'casino' : 'group';

  return (
    <>
      {notification && (
        <article className="panel">
          <p className="notification is-warning app-login__form-panel--notification">
            {notification}
          </p>
        </article>
      )}

      <ConfirmDialog
        title={`Delete ${typeName}`}
        text={`Are you sure you want to delete this ${typeName}?`}
        onConfirm={deleteCasino}
        onCancel={onCasinoFinish}
      />
    </>
  );
};

export default DeleteCasinoComponent;

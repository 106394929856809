import React, { useState } from 'react';

const ConfirmDialog = ({ title, text, onConfirm, onCancel }) => {
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);

    onConfirm();
  };

  return (
    <>
      <article className="panel">
        <div className="panel-heading">{title || 'Are you sure?'}</div>
        <div className="panel-block">{text || 'Please confirm'}</div>
        <form onSubmit={handleSubmit}>
          <div className="panel-block">
            <button
              className="button is-fullwidth is-link"
              type="submit"
              disabled={disabled}
            >
              Yes
              {disabled && <img src="/images/loading.gif" alt="Loading" />}
            </button>
            <button
              className="button is-fullwidth is-warning"
              type="button"
              disabled={disabled}
              onClick={onCancel}
            >
              No
            </button>
          </div>
        </form>
      </article>
    </>
  );
};

export default ConfirmDialog;

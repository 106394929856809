import React, { useState, useContext, useEffect } from 'react';

import AppContext from 'services/context';
import CasinoService from 'services/casino.service';
import CasinoGroupService from 'services/casino-group.service';

import './CasinoModal.css';

const CasinoModal = ({ type = 'casino', casinoId, casino, onCasinoFinish }) => {
  const { dispatch } = useContext(AppContext);

  const [name, setName] = useState('');

  const [disabled, setDisabled] = useState(false);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    if (casino) {
      setName(casino.name);
    }
  }, [casino]);

  const casinoValidation = () => {
    if (name === '') {
      setNotification('Please check the information provided.');
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (casinoValidation()) {
      setDisabled(true);
      const casinoObject = casino
        ? { name }
        : {
            name,
            groups: []
          };

      const service = type === 'casino' ? CasinoService : CasinoGroupService;

      const createOrUpdate = casino
        ? service.updateCasino(casino.uid, casinoObject)
        : service.createCasino(casinoObject, casinoId);

      const actionType = casino
        ? 'UPDATE_CASINO_IN_LIST'
        : 'ADD_CASINO_TO_LIST';

      createOrUpdate
        .then(casino => {
          let casinoObject;

          if (type === 'casino') {
            casinoObject = casino;
          } else {
            const uid = casinoId || casino.uid;
            casinoObject = { uid };
          }

          dispatch({
            type: actionType,
            payload: casinoObject
          });

          onCasinoFinish();
        })
        .catch(err => {
          setDisabled(false);
          setNotification(err.message);
        });
    }
  };

  const typeName = type === 'casino' ? 'casino' : 'table';
  const title = casino ? `Edit ${typeName}` : `Create ${typeName}`;

  return (
    <>
      <section className="app-add-casino__form-section">
        <div>
          {notification && (
            <article className="app-add-casino__form-panel">
              <p className="notification is-warning app-add-casino__form-panel--notification">
                {notification}
              </p>
            </article>
          )}

          <article className="panel app-add-casino__form-panel">
            <div className="panel-heading">{title}</div>

            <form onSubmit={handleSubmit}>
              <div className="panel-block">
                <label className="label app-add-casino__form-field">
                  Name:
                  <input
                    className="input"
                    type="text"
                    required
                    value={name}
                    onChange={({ target }) => setName(target.value)}
                  />
                </label>
              </div>
              <div className="panel-block">
                <button
                  className="button is-fullwidth is-link"
                  type="submit"
                  disabled={disabled}
                >
                  {title}
                  {disabled && <img src="/images/loading.gif" alt="Loading" />}
                </button>
                <button
                  className="button is-fullwidth is-warning"
                  type="button"
                  disabled={disabled}
                  onClick={onCasinoFinish}
                >
                  Cancel
                </button>
              </div>
            </form>
          </article>
        </div>
      </section>
    </>
  );
};

export default CasinoModal;

import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import WelcomeHeader from 'blocks/WelcomeHeader';

import AppContext from 'services/context';

const CasinoRow = ({ casino }) => {
  return (
    <tr>
      <td>
        <Link to={`/dashboard/details/${casino.uid}`}>{casino.name}</Link>
      </td>
    </tr>
  );
};

const CasinoList = () => {
  const { state } = useContext(AppContext);
  const { user, casinosList } = state;

  const [casinos, setCasinos] = useState([]);

  useEffect(() => {
    if (user) {
      const filteredCasinos = casinosList.filter(casino =>
        user.casinos.includes(casino.uid)
      );

      setCasinos(filteredCasinos);
    }
  }, [user, casinosList]);

  const casinoList = casinos.map(casino => (
    <CasinoRow key={casino.uid} casino={casino} />
  ));

  return (
    <>
      <WelcomeHeader user={user} tabs={false} />

      <hr />

      <table className="table is-bordered is-hoverable is-fullwidth">
        <thead>
          <tr>
            <td>Name</td>
          </tr>
        </thead>
        <tbody>{casinoList}</tbody>
      </table>
    </>
  );
};

export default CasinoList;

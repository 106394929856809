import React, { useState, useContext } from 'react';

import WelcomeHeader from 'blocks/WelcomeHeader';
import Modal from 'blocks/Modal';

import CasinoList from './CasinoList';
import CasinoModal from './CasinoModal';

import AppContext from 'services/context';

import './index.css';

const CasinoPage = () => {
  const { state } = useContext(AppContext);
  const { user } = state;

  const [casinoModal, setCasinoModal] = useState(false);

  return (
    <>
      <WelcomeHeader activePage="casinos" user={user} />

      <CasinoList />

      <button
        className="button is-success"
        type="button"
        onClick={() => setCasinoModal(true)}
      >
        Add casino
      </button>

      {casinoModal && (
        <Modal closeModal={() => setCasinoModal(false)}>
          <CasinoModal onCasinoFinish={() => setCasinoModal(false)} />
        </Modal>
      )}
    </>
  );
};

export default CasinoPage;

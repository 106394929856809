import React, { useState, useContext, useEffect } from 'react';

import AppContext from 'services/context';
import CasinoGroupService from 'services/casino-group.service';

import './Background.css';

const Background = ({ casino }) => {
  const { dispatch } = useContext(AppContext);

  const [notification, setNotification] = useState(null);
  const [backgroundFile, setBackgroundFile] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const { uid } = casino;

  useEffect(() => {
    if (backgroundFile === null) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [backgroundFile]);

  const handleBackgroundUpload = e => {
    e.preventDefault();

    setLoading(true);
    setDisabled(true);
    setUploadDisabled(true);
    if (backgroundFile !== null) {
      CasinoGroupService.uploadBackground(uid, backgroundFile)
        .then(background => {
          dispatch({
            type: 'UPDATE_CASINO_IN_LIST',
            payload: {
              ...casino,
              background
            }
          });

          setLoading(false);
          setDisabled(false);
          setUploadDisabled(false);
          setBackgroundFile(null);
        })
        .catch(err => {
          setLoading(false);
          setDisabled(false);
          setUploadDisabled(false);

          setNotification(err.message);
        });
    }
  };

  if (!casino.background) return null;

  return (
    <>
      <article>
        <h2 className="subtitle">Background</h2>
        <figure className="casino-background">
          <img src={casino.background.url} alt="Casino background" />
        </figure>
        <form onSubmit={handleBackgroundUpload}>
          {notification && (
            <p className="notification is-warning">{notification}</p>
          )}
          <div className="field">
            <div className="file is-info has-name">
              <label className="file-label">
                <input
                  className="file-input"
                  type="file"
                  accept=".jpg,.jpeg,.png,.gif,.bmp"
                  disabled={uploadDisabled}
                  onChange={({ target }) => {
                    setBackgroundFile(target.files[0]);
                  }}
                />
                <span className="file-cta">
                  <span className="file-icon">
                    <i className="fas fa-upload" />
                  </span>
                  <span className="file-label">Choose a file…</span>
                </span>
                <span className="file-name">
                  {backgroundFile
                    ? backgroundFile.name
                    : 'Please select a file'}
                </span>
              </label>
            </div>
          </div>

          <button
            className="button is-primary"
            type="submit"
            disabled={disabled}
          >
            Change background
            {loading && <img src="/images/loading.gif" alt="Loading" />}
          </button>
        </form>
      </article>
    </>
  );
};

export default Background;

import firebase from './firebase';

class ImageStorage {
  constructor(collection) {
    this.collection = collection;
    this.service = firebase.storage();

    this.storage = this.service.ref();
    this.collectionPath = `/${this.collection}`;
  }

  create = (id, file) => {
    const timestamp = Date.now();
    const path = `${this.collectionPath}/${id}/${timestamp}${file.name}`;
    const fileRef = this.storage.child(path);

    return fileRef.put(file);
  };

  createWithCustomName = (id, file, filename) => {
    const path = `${this.collectionPath}/${id}/${filename}`;
    const fileRef = this.storage.child(path);

    return fileRef.put(file);
  };

  delete = path => {
    const fileRef = this.storage.child(path);

    return fileRef.delete();
  };
}

export default ImageStorage;
